import { FC, HTMLProps, PropsWithChildren } from "react"
import "./index.less"

const Container: FC<PropsWithChildren<HTMLProps<HTMLDivElement>>> = ({ className, children, ...otherProps }) => {
  return (
    <div className={`container ${className ? className : ""}`} {...otherProps}>
      {children}
    </div>
  )
}

export { Container }
