import { FC } from "react"

import { NavLink, useLocation } from "react-router-dom"

import Navigation from "./Navigation"

const headerMenuItems = [
  { key: "/nurseries", label: <NavLink to={"/nurseries"}>Nurseries</NavLink> },
  {
    key: "/activities",
    label: <NavLink to={"/activities"}>Activities</NavLink>,
  },
]

const HeaderMenu: FC = () => {
  const { pathname } = useLocation()
  return (
    <Navigation
      disabledOverflow
      mode={"horizontal"}
      activeKey={pathname}
      className={"header-menu"}
      defaultActiveFirst
      items={headerMenuItems}
    />
  )
}
export { HeaderMenu }
