import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header } from "."
import { HeaderMenu } from "../menu"
import "./index.less"

interface LayoutProps {
  onlyBase?: boolean
}

const Layout: FC<LayoutProps> = () => (
  <BaseLayout>
    <BaseLayout.Header>
      <Header renderMenu={HeaderMenu} />
    </BaseLayout.Header>
    <BaseLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </BaseLayout>
    <BaseLayout.Footer>
      <Footer />
    </BaseLayout.Footer>
  </BaseLayout>
)

export { Layout }
