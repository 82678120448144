import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Checkbox: {
      borderRadius: 2,
    },
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorBgContainer: "transparent",
    },
    Button: {
      borderRadius: 8,
      controlHeight: 56,
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
    Input: {
      fontSize: 12,
      paddingBlock: 8,
      paddingInline: 16,
      colorPrimary: "rgba(30, 163, 255, 0.50)",
      activeBorderColor: "#ff1818",
    },
  },
  token: {
    fontSizeHeading1: 64,
    fontSizeHeading2: 48,
    fontSizeHeading3: 40,
    fontSizeHeading4: 36,
    fontSizeHeading5: 32,
    lineHeightHeading1: 1.12,
    lineHeightHeading2: 1.12,
    lineHeightHeading3: 1.12,
    lineHeightHeading4: 1.12,
    lineHeightHeading5: 1.12,
    fontFamily: "Lato",
    colorPrimary: "#1EA3FF",
    colorLink: "#1EA3FF",
    colorInfo: "#1EA3FF",
  },
}

export default theme
