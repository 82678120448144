import { FC } from "react"

import { ReactComponent as LogoImage } from "./logo-my-kg.svg"

interface LogoProps {
  height?: number
  width?: number
  noLink?: boolean
}

const Logo: FC<LogoProps> = ({ noLink, height, width }) => {
  return (
    <LogoImage
      onClick={!noLink ? () => window.location.assign("/") : () => {}}
      style={{ height: height ? height : 64, width: width ? width : 64, cursor: !noLink ? "pointer" : "default" }}
      className={"logo"}
    />
  )
}

export { Logo }
