import { Button, Checkbox, Col, Form, Input, message, Row, Space, Typography } from "antd"
import TextArea from "antd/es/input/TextArea"
import { FC } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useCreateProviderMutation } from "../../../graphql"

const BecameProviderForm: FC = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [createProvider] = useCreateProviderMutation()

  const onFinish = async (input: any) => {
    await createProvider({ variables: { input }, onCompleted: () => message.success("Request sent successfully!") })
  }

  const commonRules = [
    {
      required: true,
      message: "",
    },
  ]

  return (
    <Form
      form={form}
      variant={"filled"}
      name={"became-provider-form"}
      onFinish={onFinish}
      layout={"vertical"}
      className={"became-provider-form"}
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item rules={commonRules} name={"firstName"}>
            <Input placeholder={"First Name"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={commonRules} name={"lastName"}>
            <Input placeholder={"Last Name"} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item rules={commonRules} name={"email"}>
        <Input placeholder={"Email Address"} />
      </Form.Item>
      <Form.Item rules={commonRules} name={"phone"}>
        <Input placeholder={"Phone Number"} />
      </Form.Item>
      <Form.Item name={"message"}>
        <TextArea placeholder={"Brief Message"} />
      </Form.Item>
      <Form.Item name={"marketingEmails"} valuePropName='checked'>
        <Checkbox className={"privacy-policy"}>
          <Space size={4} direction={"horizontal"}>
            <Typography.Text>
              {`"Send Message" you agree to receive marketing communication in according with our `}
              <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
            </Typography.Text>
          </Space>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} className={"submit-btn"} block htmlType={"submit"}>
          {"Send Message"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export { BecameProviderForm }
