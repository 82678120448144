import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Activity",
      "City",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Department",
      "Facility",
      "I18NLocale",
      "Language",
      "Layout",
      "Nursery",
      "NurserySystem",
      "Payment",
      "Provider",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Activity",
      "City",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Department",
      "Facility",
      "I18NLocale",
      "Language",
      "Layout",
      "Nursery",
      "NurserySystem",
      "Payment",
      "Provider",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CityFragmentDoc = gql`
    fragment City on CityEntity {
  id
  attributes {
    value
  }
}
    `;
export const LanguageFragmentDoc = gql`
    fragment Language on LanguageEntity {
  id
  attributes {
    value
  }
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on PaymentEntity {
  id
  attributes {
    icon {
      data {
        attributes {
          url
        }
        id
      }
    }
    name
  }
}
    `;
export const ActivityFragmentDoc = gql`
    fragment Activity on ActivityEntity {
  id
  attributes {
    title
    price
    logo {
      data {
        ...File
      }
    }
    date
    phone
    whatsapp
    email
    whatsapp
    website
    description
    date
    street
    location
    city {
      data {
        ...City
      }
    }
    agesAccepted
    languages {
      data {
        ...Language
      }
    }
    img {
      data {
        ...File
      }
    }
    gallery_images {
      data {
        attributes {
          alternativeText
          url
        }
      }
    }
    payments {
      data {
        ...Payment
      }
    }
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const NurserySystemFragmentDoc = gql`
    fragment NurserySystem on NurserySystemEntity {
  id
  attributes {
    value
  }
}
    `;
export const FacilityFragmentDoc = gql`
    fragment Facility on FacilityEntity {
  id
  attributes {
    value
  }
}
    `;
export const DepartmentFragmentDoc = gql`
    fragment Department on DepartmentEntity {
  id
  attributes {
    name
    logo {
      data {
        attributes {
          name
          url
        }
        id
      }
    }
    city
    street
    locationUrl
  }
}
    `;
export const NurseryFragmentDoc = gql`
    fragment Nursery on NurseryEntity {
  id
  attributes {
    title
    price
    priceAdditionalInfo
    logo {
      data {
        ...File
      }
    }
    description
    street
    location
    city {
      data {
        ...City
      }
    }
    phone
    email
    whatsapp
    website
    nursery_systems {
      data {
        ...NurserySystem
      }
    }
    agesAccepted
    languages {
      data {
        ...Language
      }
    }
    payments {
      data {
        ...Payment
      }
    }
    previewImg {
      data {
        ...File
      }
    }
    facilities {
      data {
        ...Facility
      }
    }
    departments {
      data {
        ...Department
      }
    }
    gallery_images {
      data {
        attributes {
          url
          previewUrl
          alternativeText
        }
      }
    }
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const ProviderFragmentDoc = gql`
    fragment Provider on ProviderEntity {
  id
  attributes {
    email
    firstName
    lastName
    email
    message
    phone
    marketingEmails
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const CreateProviderDocument = gql`
    mutation createProvider($input: ProviderInput!) {
  createProvider(data: $input) {
    data {
      ...Provider
    }
  }
}
    ${ProviderFragmentDoc}`;
export type CreateProviderMutationFn = Apollo.MutationFunction<CreateProviderMutation, CreateProviderMutationVariables>;
export function useCreateProviderMutation(baseOptions?: Apollo.MutationHookOptions<CreateProviderMutation, CreateProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProviderMutation, CreateProviderMutationVariables>(CreateProviderDocument, options);
      }
export type CreateProviderMutationHookResult = ReturnType<typeof useCreateProviderMutation>;
export type CreateProviderMutationResult = Apollo.MutationResult<CreateProviderMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: String!) {
  forgotPassword(email: $input) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      username
      confirmed
      role {
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      username
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!, $confirmPassword: String!) {
  resetPassword(
    code: $token
    password: $password
    passwordConfirmation: $confirmPassword
  ) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const ActivitiesDocument = gql`
    query activities {
  activities {
    data {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}
${FileFragmentDoc}
${CityFragmentDoc}
${LanguageFragmentDoc}
${PaymentFragmentDoc}`;
export function useActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
      }
export function useActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
        }
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<ActivitiesQuery, ActivitiesQueryVariables>;
export const ActivityDocument = gql`
    query activity($key: IDFilterInput!) {
  activities(filters: {id: $key}) {
    data {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}
${FileFragmentDoc}
${CityFragmentDoc}
${LanguageFragmentDoc}
${PaymentFragmentDoc}`;
export function useActivityQuery(baseOptions: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
      }
export function useActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
        }
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<typeof useActivityLazyQuery>;
export type ActivityQueryResult = Apollo.QueryResult<ActivityQuery, ActivityQueryVariables>;
export const CitiesDocument = gql`
    query cities {
  cities {
    data {
      ...City
    }
  }
}
    ${CityFragmentDoc}`;
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const LanguagesDocument = gql`
    query languages {
  languages {
    data {
      ...Language
    }
  }
}
    ${LanguageFragmentDoc}`;
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
        }
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    username
    lastName
    firstName
    email
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NurseriesDocument = gql`
    query nurseries {
  nurseries {
    data {
      ...Nursery
    }
  }
}
    ${NurseryFragmentDoc}
${FileFragmentDoc}
${CityFragmentDoc}
${NurserySystemFragmentDoc}
${LanguageFragmentDoc}
${PaymentFragmentDoc}
${FacilityFragmentDoc}
${DepartmentFragmentDoc}`;
export function useNurseriesQuery(baseOptions?: Apollo.QueryHookOptions<NurseriesQuery, NurseriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NurseriesQuery, NurseriesQueryVariables>(NurseriesDocument, options);
      }
export function useNurseriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NurseriesQuery, NurseriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NurseriesQuery, NurseriesQueryVariables>(NurseriesDocument, options);
        }
export type NurseriesQueryHookResult = ReturnType<typeof useNurseriesQuery>;
export type NurseriesLazyQueryHookResult = ReturnType<typeof useNurseriesLazyQuery>;
export type NurseriesQueryResult = Apollo.QueryResult<NurseriesQuery, NurseriesQueryVariables>;
export const NurserySystemsDocument = gql`
    query nursery_systems {
  nurserySystems {
    data {
      ...NurserySystem
    }
  }
}
    ${NurserySystemFragmentDoc}`;
export function useNurserySystemsQuery(baseOptions?: Apollo.QueryHookOptions<NurserySystemsQuery, NurserySystemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NurserySystemsQuery, NurserySystemsQueryVariables>(NurserySystemsDocument, options);
      }
export function useNurserySystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NurserySystemsQuery, NurserySystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NurserySystemsQuery, NurserySystemsQueryVariables>(NurserySystemsDocument, options);
        }
export type NurserySystemsQueryHookResult = ReturnType<typeof useNurserySystemsQuery>;
export type NurserySystemsLazyQueryHookResult = ReturnType<typeof useNurserySystemsLazyQuery>;
export type NurserySystemsQueryResult = Apollo.QueryResult<NurserySystemsQuery, NurserySystemsQueryVariables>;
export const NurseryDocument = gql`
    query nursery($key: IDFilterInput!) {
  nurseries(filters: {id: $key}) {
    data {
      ...Nursery
    }
  }
}
    ${NurseryFragmentDoc}
${FileFragmentDoc}
${CityFragmentDoc}
${NurserySystemFragmentDoc}
${LanguageFragmentDoc}
${PaymentFragmentDoc}
${FacilityFragmentDoc}
${DepartmentFragmentDoc}`;
export function useNurseryQuery(baseOptions: Apollo.QueryHookOptions<NurseryQuery, NurseryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NurseryQuery, NurseryQueryVariables>(NurseryDocument, options);
      }
export function useNurseryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NurseryQuery, NurseryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NurseryQuery, NurseryQueryVariables>(NurseryDocument, options);
        }
export type NurseryQueryHookResult = ReturnType<typeof useNurseryQuery>;
export type NurseryLazyQueryHookResult = ReturnType<typeof useNurseryLazyQuery>;
export type NurseryQueryResult = Apollo.QueryResult<NurseryQuery, NurseryQueryVariables>;