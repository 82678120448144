import { FC } from "react"

import { Typography } from "antd"
import { Logo } from "src/components/logo"
import bgLineImg from "../../../assets/images/blue-pic.png"
import { BecameProviderForm } from "../../forms"
import "./index.less"

const BecameProviderSection: FC = () => {
  return (
    <section className={"became-provider-section"}>
      <div className={"aside-content"}>
        <div className={"bg-line"}>
          <img className={"bg-line-image"} src={bgLineImg} alt={"bg-line"} />
        </div>
      </div>
      <div className={"content"}>
        <Logo width={57} height={64} noLink />
        <div>
          <Typography.Title className={"section-title"} level={4}>
            {"Join Our Network of Providers"}
          </Typography.Title>
          <Typography.Text type={"secondary"} className={"description"}>
            {
              "Please fill out the form below to register as a provider on our platform. We look forward to partnering with you!"
            }
          </Typography.Text>
          <BecameProviderForm />
        </div>
      </div>
    </section>
  )
}

export { BecameProviderSection }
