import { FC, lazy, Suspense } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { Layout as BaseLayout } from "antd";
import DefaultLayout, { Loader } from "../components/layout";

const Home = lazy<FC>(() => import("./home"));
const Activities = lazy<FC>(() => import("./activities"));
const Activity = lazy<FC>(() => import('./activities/activity'));
const Nurseries = lazy<FC>(() => import("./nurseries"));
const Nursery = lazy(() => import('./nurseries/nursery'));
const Login = lazy<FC>(() => import("./login"));
const SignUp = lazy<FC>(() => import("./sign-up"));
const ForgotPassword = lazy<FC>(() => import("./forgot-password"));
const GoogleRedirect = lazy<FC>(() => import("./google-redirect"));
const FacebookRedirect = lazy<FC>(() => import("./facebook-redirect"));
const ResetPassword = lazy<FC>(() => import("./reset-password"));
const ForgotPasswordSuccess = lazy<FC>(() => import("./forgot-password-success"));

const BaseAntdLayout = () => (
  <BaseLayout className={"base-layout"}>
    <Suspense fallback={<Loader spinning />}>
      <Outlet />
    </Suspense>
  </BaseLayout>
);

const routes: RouteObject[] = [
  {
    Component: BaseAntdLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Login,
        path: "/login",
      },
      {
        Component: SignUp,
        path: "/sign-up",
      },
      {
        Component: ForgotPassword,
        path: "/forgot-password",
      },
      {
        Component: ForgotPasswordSuccess,
        path: "/forgot-password/success",
      },
      {
        Component: ResetPassword,
        path: "/reset-password",
      },
      {
        Component: GoogleRedirect,
        path: "connect/google/redirect",
      },
      {
        Component: FacebookRedirect,
        path: "connect/facebook/redirect",
      },
    ],
  },
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Activities,
        path: "/activities",
      },
      {
        Component: Activity,
        path: "/activity/:id",
      },
      {
        Component: Nurseries,
        path: "/nurseries",
      },
      {
        Component: Nursery,
        path: "/nursery/:id",
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
];

export default routes;
