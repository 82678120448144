import { Button, Modal, Row } from "antd"
import { FC,useState } from "react"

import { useMeQuery } from "src/graphql"
import { AccountMenu } from "../account"
import { Container } from "../container"
import { Logo } from "../logo"
import { HeaderMenu } from "../menu"
import { BecameProviderSection } from "../section/became-provider"

const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  const { data: me, loading } = useMeQuery()

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const dynamicItems = (
    <Row className={"btn-row"}>
      <Button size={"small"} type={"text"} onClick={showModal}>
        Became Provider
      </Button>
      <Button href={"/login"} size={"small"} type={"default"}>
        Log In
      </Button>
    </Row>
  )

  return (
    <Container>
      <Row align={"middle"} justify={"space-between"}>
        <HorizontalMenu />
        <Logo />
        {me && !loading ? <AccountMenu lastName={me?.me?.lastName} firstName={me?.me?.firstName} username={me?.me?.username}/> : dynamicItems}
      </Row>
      <Modal width={"65%"} open={isModalVisible} onCancel={handleCancel} footer={null}>
        <BecameProviderSection />
      </Modal>
    </Container>
  )
}

export { Header }
