import { Divider, Row, Typography } from "antd"
import { FC } from "react"
import { NavLink } from "react-router-dom"
import { Container } from "../container"
import { Logo } from "../logo"
import Navigation from "../menu/Navigation"

const Footer: FC = () => {
  return (
    <Container>
      <section className={"footer"}>
        <Row justify={"space-between"} className={"footer-row"}>
          <Navigation
            className={"footer-menu"}
            items={[
              {
                key: "/nurseries",
                className: "footer-menu-item",
                label: <NavLink to={"/nurseries"}>Nurseries</NavLink>,
              },
              {
                key: "/activities",
                className: "footer-menu-item",
                label: <NavLink to={"/activities"}>Activities</NavLink>,
              },
            ]}
          />
          <Row justify={"center"} className={"logo-wrapper"}>
            <Logo width={42} height={48} />
            <Typography.Text className={"logo-text"}>MyKG</Typography.Text>
          </Row>
          <Navigation
            className={"footer-menu"}
            items={[
              {
                key: "/privacy-policy",
                className: "footer-menu-item",
                label: <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>,
              },
              {
                key: "/terms-and-conditions",
                className: "footer-menu-item",
                label: <NavLink to={"/terms-and-conditions"}>Terms and Conditions</NavLink>,
              },
            ]}
          />
        </Row>
        <Divider />
        <Row justify={"center"}>
          <Typography.Text type={"secondary"} className={"footer-text"}>
            &copy; {new Date().getFullYear()} MyKG. All Rights Reserved
          </Typography.Text>
        </Row>
      </section>
    </Container>
  )
}

export { Footer }
