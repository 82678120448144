import { Dropdown, MenuProps, Row } from "antd"
import { FC } from "react"
import { HiChevronDown } from "react-icons/hi"
import { NavLink } from "react-router-dom"
import { removeAuthToken } from "../../helpers/auth"
import "./index.less"

interface AccountMenuProps {
  firstName: Maybe<string>
  lastName: Maybe<string>
  username: Maybe<string>
}

const AccountMenu: FC<AccountMenuProps> = ({ firstName, lastName, username }) => {
  const handleLogOut = () => {
    removeAuthToken()
    window.location.replace("/login")
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <NavLink target={"_blank"} rel={"noopener noreferrer"} to={"/settings"}>
          Settings
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink to={"/"} rel={"noopener noreferrer"} onClick={handleLogOut}>
          Log Out
        </NavLink>
      ),
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement={"bottomRight"}
      className={"account-menu"}
      overlayClassName={"menu-dropdown"}
    >
      <Row align={"middle"}>
        <div className={"account-info"}>
          <span className={"name"}>{firstName ?? username}</span>
          <span className={"surname"}>{lastName}</span>
        </div>
        <HiChevronDown size={22} />
      </Row>
    </Dropdown>
  )
}

export { AccountMenu }
